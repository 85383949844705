var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PromotionAgFilters', {
    attrs: {
      "type-filter": _vm.typeFilter,
      "start-date": _vm.startDate,
      "end-date": _vm.endDate,
      "airline": _vm.airline
    },
    on: {
      "update:typeFilter": function updateTypeFilter($event) {
        _vm.typeFilter = $event;
      },
      "update:type-filter": function updateTypeFilter($event) {
        _vm.typeFilter = $event;
      },
      "update:startDate": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:start-date": function updateStartDate($event) {
        _vm.startDate = $event;
      },
      "update:endDate": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:end-date": function updateEndDate($event) {
        _vm.endDate = $event;
      },
      "update:airline": function updateAirline($event) {
        _vm.airline = $event;
      },
      "fetch-data": _vm.refetchData,
      "reset": _vm.clearFilter
    }
  }), _c('b-card', {
    staticClass: "mb-0",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-row', {
    staticClass: "justify-content-md-between flex-md-nowrap",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, [_c('span', {
    staticClass: "text-muted ml-50"
  }, [_vm._v(_vm._s(_vm.$t('promotionAg.promotions')))])])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {}, [_vm.canCreate ? _c('b-button', {
    staticClass: "px-lg-1 px-sm-75",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-promotionAg-create'
        });
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('promotionAg.Create')))])], 1)]) : _vm._e()], 1)])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refPromotionsListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "46vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchPromotionsByFilter,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("promotionAg.tableTitle.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.sizePerPage + data.index + 1) + " ")])])];
      }
    }, {
      key: "cell(code)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-inline text-nowrap text-info",
          attrs: {
            "disabled": !_vm.canAccess('employee.detailEmployee'),
            "to": {
              name: 'apps-promotionAg-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold",
          staticStyle: {
            "font-family": "monospace"
          }
        }, [_vm._v(" " + _vm._s(data.item.code) + " ")])]), _c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "max-width": "120px"
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            value: data.item.name,
            expression: "data.item.name",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }],
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])])], 1)];
      }
    }, {
      key: "cell(name)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap",
          staticStyle: {
            "max-width": "120px"
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            value: data.item.name,
            expression: "data.item.name",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }],
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])])];
      }
    }, {
      key: "cell(paidType)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.item.paidType))) + " ")];
      }
    }, {
      key: "cell(minimumPassenger)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.minimumPassenger) + " ")];
      }
    }, {
      key: "cell(agencies)",
      fn: function fn(data) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            value: data.item.agencies ? data.item.agencies.join(', ') : '',
            expression: "data.item.agencies ? data.item.agencies.join(', ') : ''",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }],
          staticClass: "text-nowrap text-truncate",
          staticStyle: {
            "max-width": "200px"
          }
        }, [_vm._v(" " + _vm._s(data.item.agencies ? data.item.agencies.join(', ') : '') + " ")])];
      }
    }, {
      key: "cell(flightTrips)",
      fn: function fn(data) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            value: data.item.flightTrips ? data.item.flightTrips.join(', ') : '',
            expression: "data.item.flightTrips ? data.item.flightTrips.join(', ') : ''",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }],
          staticClass: "text-nowrap text-truncate",
          staticStyle: {
            "max-width": "200px"
          }
        }, [_vm._v(" " + _vm._s(data.item.flightTrips ? data.item.flightTrips.join(', ') : '') + " ")])];
      }
    }, {
      key: "cell(flightTypes)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.flightType) + " ")])];
      }
    }, {
      key: "cell(discount)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.discountAmount ? _vm.formatCurrency(data.item.discountAmount) : data.item.discountPercent) + " " + _vm._s(data.item.discountPercent > 0 ? '%' : 'VND') + " ")])];
      }
    }, {
      key: "cell(flightStartTime)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b', [_vm._v("BĐ:")]), _vm._v(" " + _vm._s(data.item.flightStartTime && _vm.convertISODateTime(data.item.flightStartTime.substr(0, 10)).date) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_c('b', [_vm._v("KT:")]), _vm._v(" " + _vm._s(data.item.flightEndTime && _vm.convertISODateTime(data.item.flightEndTime.substr(0, 10)).date) + " ")])];
      }
    }, {
      key: "cell(startTime)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b', [_vm._v("BĐ:")]), _vm._v(" " + _vm._s(data.item.startTime && _vm.convertISODateTime(data.item.startTime.substr(0, 10)).date) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_c('b', [_vm._v("KT:")]), _vm._v(" " + _vm._s(data.item.endTime && _vm.convertISODateTime(data.item.endTime.substr(0, 10)).date) + " ")])];
      }
    }, {
      key: "cell(quota)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b', [_vm._v("Số tiền:")]), _vm._v(" " + _vm._s(data.item.appliedAmount) + " / " + _vm._s(data.item.quotaAmount) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_c('b', [_vm._v("Số phiếu:")]), _vm._v(" " + _vm._s(data.item.appliedCounter) + " / " + _vm._s(data.item.quotaCounter) + " ")])];
      }
    }, {
      key: "cell(active)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          staticClass: "mr-0 mt-50 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": !_vm.canCreate
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              _vm.canCreate && _vm.changeActive(data.item);
            }
          },
          model: {
            value: data.item.active,
            callback: function callback($$v) {
              _vm.$set(data.item, "active", $$v);
            },
            expression: "data.item.active"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_vm.canEdit(data.item) ? _c('b-button', {
          staticClass: "px-1",
          attrs: {
            "variant": "flat-danger",
            "disabled": !_vm.canAccess('customer.deleteCustomer')
          },
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(data.item.id);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "TrashIcon",
            "size": "16"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")]), _c('small', {
          staticClass: "text-nowrap"
        }, [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            value: _vm.$t('Username'),
            expression: "$t('Username')",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }]
        }, [_vm._v(_vm._s(data.item.createdBy || ''))]), _vm._v(" ("), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            value: _vm.$t('Agency'),
            expression: "$t('Agency')",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }]
        }, [_vm._v(_vm._s(data.item.createdAgency || ''))]), _vm._v(") ")])])], 1)];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")]), _c('small', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.updatedBy || '') + " ")])])], 1)];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-start",
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(_vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " "), _c('span', {
    staticClass: "d-inline-block d-sm-none d-md-inline"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.outOf')) + " ")]), _c('span', {
    staticClass: "d-none d-sm-inline d-md-none"
  }, [_vm._v(" / ")]), _vm._v(" " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalPromotions,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }